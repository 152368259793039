import React, { createContext, useState, useEffect, useContext } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { FiPlay, FiPause, FiPlusCircle } from "react-icons/fi";
import {
  BiDotsVerticalRounded,
  BiVolumeMute,
  BiVolumeFull,
} from "react-icons/bi";
import {
  RiStopCircleLine,
  RiAlertLine,
  RiPlayList2Fill,
  RiHeartFill,
  RiHeartLine,
  RiBitCoinLine,
  RiMusic2Line,
  RiShareLine,
} from "react-icons/ri";
import {
  MdOutlineSkipNext,
  MdOutlineSkipPrevious,
  MdOutlineReorder,
  MdRepeatOne,
  MdRepeat,
  MdOutlineShuffle,
  MdClose,
} from "react-icons/md";
import { useQuery, useMutation } from "urql";
import {
  InsertFlagMutation,
  GetAllPlaylistsQuery,
  InsertPlaylistMutation,
  InsertPlaylistTrackMutation,
  InsertListenMutation,
  UpdateListenMutation,
  InsertLikeMutation,
  DeleteLikeMutation,
  CheckTrackLikeQuery,
} from "../graphql";
import "../assets/css/music.css";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AppUrl } from "../config";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Modal,
  ModalBody,
  Textarea,
  Input,
  Avatar,
} from "@windmill/react-ui";
import { Loader } from "../icons";
import { Popover } from "react-tiny-popover";
import { baseEndPoint } from "../config";
import { AuthContext } from "./AuthContext";
import useFetch, { CachePolicies } from "use-http";
export const MusicContext = createContext({});
export const MusicProvider = ({ children }) => {
  const history = useHistory();
  const { setAuthData, auth } = useContext(AuthContext);
  const { post, response, loading, error } = useFetch(baseEndPoint, {
    headers: { Authorization: `Bearer ${auth?.data?.accessToken}` },
    cachePolicy: "no-cache",
  });
  const { addToast } = useToasts();
  const [donateModal, setDonateModal] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likeId, setLikeId] = useState(null);
  const [fund, setFund] = useState(0);
  const [music, setMusic] = useState([]);
  const [currentMusic, setCurrentMusic] = useState({});
  const [menuDropdown, setMenuDropdown] = useState(false);
  const [insertFlag, executeInsert] = useMutation(InsertFlagMutation);
  const [reason, setReason] = useState("");
  const [open, setOpen] = useState(false);
  const [donateArtist, setDonateArtist] = useState("");
  const [audioInstance, setAudioInstance] = useState();
  const [audioQuality, setAudioQuality] = useState();
  const [playlistLoading, setLoading] = useState(false);
  const [playlistModal, setPlaylistModal] = useState(false);
  const [playlist, setPlaylist] = useState({ name: "", id: "", track_id: "" });
  const [GetAllPlaylist, executePlaylistQuery] = useQuery({
    query: GetAllPlaylistsQuery,
    variables: { id: auth?.data?.id },
  });
  const { fetching: getPlaylistFetching, data: getPlaylistData } =
    GetAllPlaylist;
  const [insertPlaylist, executeInsertMutation] = useMutation(
    InsertPlaylistMutation
  );
  const [insertPlaylistTrackMutation, executeInsertTrackMutation] = useMutation(
    InsertPlaylistTrackMutation
  );
  const [insertListenMutation, executeInsertListenMutation] =
    useMutation(InsertListenMutation);
  const [updateListenMutation, executeUpdateListenMutation] =
    useMutation(UpdateListenMutation);

  const [checkLikeResult] = useQuery({
    query: CheckTrackLikeQuery,
    variables: {
      track_id: currentMusic?.id,
      id: auth?.data?.id,
    }
  })

  const [insertLikeResult, insertLike] = useMutation(InsertLikeMutation)
  const [deleteLikeResult, deleteLike] = useMutation(DeleteLikeMutation)


  useEffect(() => {
    if (checkLikeResult.data) {
      const liked = checkLikeResult.data.like.length > 0;
      setIsLiked(liked);
      setLikeId(liked ? checkLikeResult.data.like[0].id : null);
    }
  }, [checkLikeResult.data]);

  const toggleLike = async () => {
    if (isLiked) {
      await deleteLike({ id: likeId })
      setIsLiked(false);
      setCurrentMusic({ ...currentMusic, likes: currentMusic.likes - 1 });
    } else {
      const res = await insertLike({ track_id: currentMusic.id })
      if (res.data) {
        setIsLiked(true);
        setCurrentMusic({ ...currentMusic, likes: currentMusic.likes + 1 });
      }
    }
  }

  const insertListen = async (id, listened_time) => {
    const insert = await executeInsertListenMutation({
      track_id: id,
      listened_time: listened_time,
    });
    if (insert?.data?.insert_listen_one?.id) {
      setCurrentMusic({
        ...currentMusic,
        listen_id: insert?.data?.insert_listen_one?.id,
      });
    }
  };
  const updateListen = async (listened_time) => {
    if (currentMusic?.listen_id) {
      const update = await executeUpdateListenMutation({
        listened_time: parseInt(listened_time),
        id: currentMusic?.listen_id,
      });
    } else {
      insertListen(currentMusic?.id, parseInt(audioInstance.currentTime));
    }
  };
  // get the lyrics and set in text form
  const lyrics = async (fileName) => {
    const get = await fetch(fileName)
      .then((response) => response.text())
      .then((data) => {
        return data;
      });
    return get;
  };
  const setMusicData = async (data) => {
    if (currentMusic?.listen_id) {
      await updateListen(audioInstance.currentTime);
    }
    const m = music;
    if (Array.isArray(data)) {
      setMusic([...data, ...music]);
    } else {
      setMusic([data, ...music]);
    }
  };
  //add to playlist
  async function addToPlaylist(type, playlistId) {
    setLoading(true);
    let playlist_id;
    try {
      if (type === "create") {
        const insert = await executeInsertMutation({
          title: playlist?.name,
          thumbnail: "https://app.mccoast.click/playlisthumb.png",
        });
        if (insert?.data?.insert_playlist_one?.id) {
          playlist_id = insert?.data?.insert_playlist_one?.id;
          executePlaylistQuery({ requestPolicy: "network-only" });
        } else {
          throw "INSERTION_ERROR";
        }
      } else {
        playlist_id = playlistId;
      }
      const insertTrack = await executeInsertTrackMutation({
        playlist_id: playlist_id,
        track_id: currentMusic?.id,
      });
      if (insertTrack?.data?.insert_playlist_track_one?.id) {
        addToast("Song Added to Playlist", {
          appearance: "success",
          autoDismiss: true,
        });
      } else if (insertTrack?.error?.message?.includes("Uniqueness")) {
        throw "Song Already Available in the Playlist";
      } else {
        throw "Song Couldn't be added";
      }
    } catch (e) {
      addToast(e, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setLoading(false);
    setPlaylistModal(false);
    setPlaylist({});
  }
  //report song
  const report = async () => {
    try {
      if (reason?.trim() === "") {
        throw "Reason Required";
      }
      const ins = await executeInsert({
        track_id: currentMusic?.id,
        reason: reason,
      });
      if (ins?.data?.insert_flag_one?.id) {
        setOpen(false);
        addToast("Reported the Song", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        if (ins?.error?.message?.includes("Uniqueness violation")) {
          setOpen(false);
          throw "Already Reported";
        }
        throw "Unable To Report The Song";
      }
    } catch (e) {
      addToast(e, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  //donate to artist
  const donate = async () => {
    try {
      if (fund < 0.5) {
        throw "You can't donate lesser 0.5 USD ";
      }
      const getSession = await post("payment/get-session", {
        artist_id: donateArtist,
        fund: parseFloat(fund),
        redirect_link: `${AppUrl}/artists/${donateArtist}`,
      });
      if (getSession?.data?.session_url) {
        addToast("You'll be redirected to payment page", {
          appearance: "success",
          autoDismiss: true,
        });
        window.location.href = getSession?.data?.session_url;
      } else if (
        getSession?.status === "error" &&
        getSession?.error?.code === "AUTH_FAILED"
      ) {
        throw "Kindly Refresh the page and try again";
      } else {
        throw "Unable To Create a Donate Session";
      }
    } catch (e) {
      addToast(e, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setDonateArtist("");
  };

  //extended contents
  const extendsContent = (
    <div className="flex">
      <span onClick={toggleLike} className="btn btn-gradient rounded-full w-16 h-10 text-md text-white font-semibold  flex items-center justify-around cursor-pointer">
        {/* <RiHeartFill
          // onClick={isLiked ? () => deleteLike(currentMusic?.like_id) : () => insertLike(currentMusic?.id)}
          className="text-fuchsia-300" color="#f0abfc"
        />{" "} */}
        {/* <button > */}
          {isLiked ? <RiHeartFill /> : <RiHeartLine />}
        {/* </button> */}
        {currentMusic?.likes > 1000
          ? `${currentMusic?.likes / 1000}K`
          : currentMusic?.likes || 0}
      </span>
      <Popover
        isOpen={menuDropdown}
        positions={["top"]}
        onClickOutside={() => {
          setMenuDropdown(false);
        }}
        content={({ position, nudgedLeft, nudgedTop }) => (
          <div className="bg-white p-4 rounded-lg mb-1 top-auto right-0  text-black">
            <div className="flex items-center mb-4 space-x-2  border-b-2 pb-4">
              <img
                src={currentMusic?.cover}
                alt="song cover"
                className="rounded-lg w-10 h-10 object-cover"
              />
              <div className="text-left">
                <h5 className="font-semibold text-sm">{currentMusic?.name}</h5>
              </div>
            </div>

            <ul className="flex flex-col ">
              <li>
                <button
                  className="flex items-center space-x-2 text-sm hover:bg-purple-100 p-2 rounded w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setPlaylistModal(true);
                  }}
                >
                  <RiPlayList2Fill />
                  <span>Add To Playlist</span>
                </button>
              </li>
              <li>
                <button
                  className="flex items-center space-x-2 text-sm hover:bg-purple-100 p-2 rounded w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setDonateArtist(currentMusic?.artist_id);
                    setDonateModal(true);
                  }}
                >
                  <RiBitCoinLine />
                  <span>Donate To Artist</span>
                </button>
              </li>
              <li>
                <button
                  className="flex items-center space-x-2 text-sm hover:bg-purple-100 p-2 rounded w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/songs/${currentMusic?.id}`);
                  }}
                >
                  <RiMusic2Line />
                  <span>View Music Info</span>
                </button>
              </li>
              <li>
                <button
                  className="flex items-center space-x-2 text-sm hover:bg-purple-100 p-2 rounded w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/artists/${currentMusic?.artist_id}`);
                  }}
                >
                  <RiAlertLine />
                  <span>View Artist Info</span>
                </button>
              </li>
              <li>
                <CopyToClipboard
                  text={`${AppUrl}/songs/${currentMusic?.id}`}
                  onCopy={() => {
                    addToast("Song URL copied", {
                      appearance: "success",
                      autoDismiss: true,
                    });
                  }}
                >
                  <button
                    className="flex items-center space-x-2 text-sm hover:bg-purple-100 p-2 rounded w-full"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <RiShareLine />
                    <span>Share</span>
                  </button>
                </CopyToClipboard>
              </li>{" "}
              <li>
                <button
                  className="flex items-center space-x-2 text-sm hover:bg-purple-100 p-2 rounded w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                >
                  <RiStopCircleLine />
                  <span>Report</span>
                </button>
              </li>
            </ul>
          </div>
        )}
      >
        <button onClick={() => setMenuDropdown(!menuDropdown)}>
          <BiDotsVerticalRounded className="w-6 h-10 text-black" color="#000" />
        </button>
      </Popover>
      <button
        className="right-0 absolute align-baseline "
        onClick={(e) => {
          audioInstance.pause();
          updateListen(audioInstance.currentTime);
          setCurrentMusic({});
          setMusic([]);
        }}
      >
        <MdClose color="#000" className="w-6 h-10 text-black" />
      </button>
    </div>
  );
  useEffect(() => {
    setAudioQuality(localStorage.getItem("audio_quality"));

  }, []);
  useEffect(() => {
    setAudioQuality(localStorage.setItem("audio_quality", audioQuality));
  }, [audioQuality]);

  return (
    <MusicContext.Provider
      value={{
        music,
        setMusicData,
        currentMusic,
        donateModal,
        setDonateModal,
        setDonateArtist,
        lyrics,
        setAudioQuality,
        audioQuality,
      }}
    >
      {children}
      {/* report song modal */}
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <ModalBody>
          <div>
            <h3 className="text-2xl font-bold text-center">Report Song</h3>

            <Textarea
              className="bg-fuchsia-100 border-none rounded-lg text-xs py-3"
              placeholder="Reason To Report"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />

            <div className="flex items-center space-x-2 mt-6">
              <Button
                className="w-1/2 py-4 text-fuchsia-800 border-fuchsia-800 text-lg"
                layout="outline"
                onClick={() => setOpen(false)}
              >
                Back
              </Button>
              <Button
                className="w-1/2 py-4 btn-gradient text-lg"
                icon={insertFlag?.fetching ? Loader : null}
                onClick={(e) => {
                  e.preventDefault();
                  report();
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* playlist modal */}
      <Modal isOpen={playlistModal} onClose={() => setPlaylistModal(false)}>
        <ModalBody>
          <div>
            <h3 className="text-2xl font-bold text-center">Add To Playlist</h3>

            <div className="overflow-y-auto scrollbar-thin w-full  scrollbar-track-white scrollbar-thumb-orange-100 h-64">
              <div className="lg:grid xl::grid-cols-2 gap-4 px-5">
                {getPlaylistFetching ? (
                  <div className="flex justify-center text-center text-sm col-span-2  bg-orange-100 rounded-lg p-4">
                    Loading
                  </div>
                ) : getPlaylistData?.playlist?.length > 0 ? (
                  getPlaylistData?.playlist?.map((p, idx) => (
                    <div
                      className={`flex items-center  text-sm ${insertPlaylistTrackMutation?.fetching ||
                          insertPlaylist?.fetching ||
                          playlistLoading
                          ? "bg-orange-50"
                          : "bg-orange-100"
                        }  rounded-lg p-4`}
                      key={idx}
                    >
                      <Avatar
                        className="hidden mr-3 md:block"
                        src={p.thumbnail}
                        alt="playlist thumb"
                      />
                      <div className="flex-1">
                        <p className="font-semibold">{p.title}</p>
                      </div>

                      <Button
                        title="add to playlist"
                        disabled={
                          insertPlaylistTrackMutation?.fetching ||
                          insertPlaylist?.fetching ||
                          playlistLoading
                        }
                        layout="link"
                        className="ml-auto "
                        size="icon"
                        onClick={(e) => {
                          e.preventDefault();
                          setPlaylist({
                            id: p?.id,
                            track_id: currentMusic?.id,
                          });
                          addToPlaylist("add", p?.id);
                        }}
                      >
                        <FiPlusCircle />
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className=""></div>
                )}
              </div>
            </div>
            <div className="text-center w-full"> or </div>
            <div className="m-3 gap-2 grid-cols-3 grid">
              <Input
                placeholder="Playlist Name"
                value={playlist?.name}
                className="bg-orange-100 col-span-2 border-none focus:outline-none"
                onChange={(e) => {
                  e.preventDefault();
                  setPlaylist({
                    track_id: currentMusic?.id,
                    name: e?.target?.value,
                  });
                }}
              />
              <Button
                title="create  playlist"
                block
                disabled={
                  insertPlaylistTrackMutation?.fetching ||
                  insertPlaylist?.fetching ||
                  playlistLoading ||
                  playlist?.name?.trim() === ""
                }
                className="ml-auto rounded-md p-2 "
                size="icon"
                onClick={(e) => {
                  e.preventDefault();
                  addToPlaylist("create");
                }}
              >
                {playlistLoading && playlist?.name?.trim() !== "" ? (
                  <Loader />
                ) : (
                  "Create & Add"
                )}{" "}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* donate modal */}
      <Modal
        isOpen={donateModal}
        onClose={() => {
          if (!loading) setDonateModal(false);
        }}
      >
        <ModalBody>
          <div>
            <h3 className="text-2xl font-bold text-center">Donate Artist</h3>

            <Input
              className="bg-fuchsia-100 border-none rounded-lg text-xs py-3"
              placeholder="Price in USD"
              type="number"
              value={fund}
              onChange={(e) => {
                setFund(e.target.value);
              }}
            />

            <div className="flex items-center space-x-2 mt-6">
              <Button
                className="w-1/2 py-4 text-fuchsia-800 border-fuchsia-800 text-lg"
                layout="outline"
                onClick={() => setDonateModal(false)}
                disabled={loading}
              >
                Back
              </Button>
              <Button
                className="w-1/2 py-4 btn-gradient text-lg"
                icon={loading ? Loader : null}
                onClick={(e) => {
                  e.preventDefault();
                  donate();
                }}
              >
                Donate
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {music?.length > 0 ? (
        <ReactJkMusicPlayer
          getAudioInstance={(instance) => {
            setAudioInstance(instance);
          }}
          onAudioError={(errMsg, currentPlayId, audioLists, audioInfo) => {
            console.log("error here", errMsg);
            audioInstance.src = audioInfo.altSrc;
            audioInstance.play();
          }}
          className="mt-0"
          clearPriorAudioLists={true}
          onAudioPlay={(audioInfo) => {
            if (audioInfo.currentTime === 0) {
              setCurrentMusic({ ...audioInfo, playing: true });
              insertListen(audioInfo.id, 0);
            } else {
              setCurrentMusic({
                ...audioInfo,
                listen_id: currentMusic?.listen_id,
                playing: true,
              });
            }
          }}
          onAudioPause={(audioInfo) => {
            setCurrentMusic({
              ...audioInfo,
              listen_id: currentMusic?.listen_id,
              playing: false,
            });
            updateListen(audioInfo.currentTime);
          }}
          onAudioEnded={(currentPlayId, audioLists, audioInfo) => {
            updateListen(audioInfo.currentTime);
          }}
          onAudioPlayTrackChange={(currentPlayId, audioLists, audioInfo) => {
            updateListen(audioInfo.currentTime);
          }}
          theme="light"
          mode="full"
          spaceBar={false}
          style={{ color: "#000", backgroundColor: "#000" }}
          icon={{
            play: (
              <button className="btn btn-gradient rounded-full w-10 h-10 text-lg text-white flex items-center justify-center">
                <FiPlay color="#fff" />
              </button>
            ),
            pause: (
              <button className="btn btn-gradient rounded-full w-10 h-10 text-lg text-white flex items-center justify-center">
                <FiPause color="#fff" />
              </button>
            ),
            lyric: (
              <button className="btn btn-gradient text-white py-3 px-6 rounded-full">
                Lyrics
              </button>
            ),
            volume: <BiVolumeFull color="#000" />,
            mute: <BiVolumeMute color="#000" />,
            prev: (
              <MdOutlineSkipPrevious
                className="w-8 h-8 cursor-pointer"
                color="#000"
              />
            ),
            next: (
              <MdOutlineSkipNext
                className="w-8 h-8 cursor-pointer"
                color="#000"
              />
            ),
            orderLoop: <MdRepeat className="player-button" color="#000" />,
            shuffle: (
              <MdOutlineShuffle className="player-button" color="#000" />
            ),
            order: <MdOutlineReorder className="player-button" color="#000" />,
            loop: <MdRepeatOne className="player-button" color="#000" />,
            playLists: (
              <RiPlayList2Fill className="player-button" color="#000" />
            ),
          }}
          autoPlay={true}
          showLyric={true}
          showDownload={false}
          toggleMode={false}
          showThemeSwitch={false}
          showReload={false}
          responsive={true}
          preload="metadata"
          audioLists={music}
          extendsContent={extendsContent}
          showProgressLoadBar={true}
          loadAudioErrorPlayNext={false}
        />
      ) : null}
    </MusicContext.Provider>
  );
};
